import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import Loading from '@/components/widgets/Loading.vue'
import Delete from '@/components/alerts/sweet-alert/alertDelete.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    Delete,
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      role: "",
      branch: "",
      loading: false, 
      advanced: false,
      status: null,
      created: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      delivery: {
        start: null,
        end: null
      },
      orderStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'รออนุมัติ', value: 'wait-approve' },
        { text: 'อนุมัติแล้ว', value: 'approved' },
        { text: 'ไม่อนุมัติ', value: 'not-approved' },
        { text: 'สำเร็จ', value: 'success' }
      ],
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'so_code', 
          label: 'เลขที่ใบรับคำสั่งซื้อ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'so_date', 
          label: 'วันที่ขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'type', 
          label: 'ชนิด', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        { 
          key: 'emp', 
          label: 'ผู้จัดทำ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'PDRY67090001',
          doc_date: new Date(),
          so_code: 'IVKR6709-00001',
          so_date: new Date(),
          emp: 'กชมน',
          approved: 'มนชิรา',
          type: 'แผ่น',
          status: '<label class="badge badge-info">On hold</label>'
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    onInitData() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    generateProductionDocument(uuid) {
      try {
        this.loading = true
        setTimeout(async () => {
          const result = await this.$axios.get(
            this.$host + "/api/test",
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob"
            }
          )
          const blob = new Blob([result.data], {type: 'application/pdf'});
          const objectUrl = URL.createObjectURL(blob)
          window.open(objectUrl,'targetWindow',
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=SomeSize,
          height=SomeSize`);

          this.loading = false
        }, 1000)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData();
    });
    this.getRole()
  },
}
